"use strict";

const tempoChanges = document.querySelector(".tempo-changes");
const endTempo = document.querySelector(".change-tempo");
const form = document.querySelector("form");
const undo = document.querySelector(".undo");
const undoAsk = document.querySelector(".undo-ask");
const trackTitleDiv = document.querySelector(".track-title");
const measureDisplay = document.querySelector(".measure-display");
const startStop = document.querySelector(".start-stop");
const clearBtn = document.querySelector(".clear-output");
const saveBtn = document.querySelector(".save-tempo-map");
const loadBtn = document.querySelector(".load-saved");
const loadSavedDiv = document.querySelector("#load-saved-div");
const loadSavedOptions = document.querySelector("#load-saved-options");
const loadSavedBtn = document.querySelector("#load-saved-btn-submit");
const loadSavedBtnCancel = document.querySelector("#load-saved-btn-cancel");
const manageStorageBtn = document.querySelector("#manage-storage-btn");
const manageStorageDiv = document.querySelector("#manage-storage-div");
const deleteOptions = document.querySelector("#delete-options");
const submitDelete = document.querySelector(".submit-delete");
const cancelDelete = document.querySelector("#delete-btn-cancel");
measureDisplay.innerHTML = `<span style="font-size: 14px; text-justify: center">Start adding measures to build your clicktrack!</span>`;
const multiples = {
  quarter: 1,
  eighth: 1 / 2,
  sixteenth: 1 / 4,
  half: 2,
  "dotted-qtr": 1.5,
  "dotted-eighth": 0.75,
};
// Variables
let tempoMap = [];
let waitTimes = [];
let countMap = [];
let audioContext;
let oscillator;
let myTimeout;
let playing = false;
let loadVisible = false;
let manageVisible = false;

// Function to build wait times
function processChunk(obj) {
  const multiple = multiples[obj.beat];
  const beats = +obj.numer * +obj.new;
  //convert BPM to wait time based on denom and multiple
  const startWait = (60 / +obj.aTemp / multiple / +obj.denom) * 4;

  let displayChange;
  // If speed changes
  if (obj.bTemp) {
    const endWait = (60 / +obj.bTemp / multiple / +obj.denom) * 4;
    const increment = Math.abs((startWait - endWait) / beats);
    let changeArr = [];
    for (let i = 0; i < beats; i++) {
      // accel
      if (+obj.aTemp < +obj.bTemp) {
        changeArr.push(startWait - increment * i);
        displayChange = "accel";
      } else {
        // slow down
        changeArr.push(startWait + increment * i);
        displayChange = "rit";
      }
    }
    waitTimes.push(...changeArr);
  } else {
    // Regular entry - no speed change
    for (let i = 0; i < beats; i++) {
      // calculate wait time from tempo, note size, beat size
      waitTimes.push(startWait);
    }
  }
  for (let i = 0; i < +obj.new; i++) {
    for (let j = 1; j <= +obj.numer; j++) {
      countMap.push(j);
    }
  }

  // console.log("countMap: ", countMap);
  // console.log("waitTimes: ", waitTimes);
  console.log("tempomap: ", tempoMap);

  // Does the new block have the same starting tempo (and ending- not defined) as the previous block?
  const addChunkSameTempo =
    tempoMap.at(-2) &&
    tempoMap.at(-2).aTemp === obj.aTemp &&
    tempoMap.at(-2).beat === obj.beat &&
    !tempoMap.at(-2).bTemp;
  const addChunkSameMeter =
    tempoMap.at(-2) &&
    tempoMap.at(-2).numer === obj.numer &&
    tempoMap.at(-2).denom === obj.denom;

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  // Make html for notes, depending on numerator
  const noteValue = ""; //sort out note icons
  const notesHtml = `<span class="${noteValue} note"></span>`.repeat(obj.numer);

  for (let i = 0; i < +obj.new; i++) {
    const html = `<div class="measure">
    <div class="tempo">
      <div class="notehead">
        <span class="${
          i === 0 && !addChunkSameTempo ? obj.beat : ""
        } beat-icon"></span>
      </div>
      <div class="tempo-bpm">${
        i === 0 && !addChunkSameTempo ? "=" + obj.aTemp : ""
      }</div>
      <div class="tempo-change">${
        i !== 0 && displayChange
          ? "-   -   -   "
          : displayChange
          ? displayChange
          : ""
      }<span id="tempo-end">${i === +obj.new - 1 ? obj.bTemp : ""}</span>
      </div>
    </div>
    <div class="bar">
      <div class="staff">
        <svg viewBox="0 0 200 50">
          <line x1="10" y1="5" x2="190" y2="5" />
          <line x1="10" y1="15" x2="190" y2="15" />
          <line x1="10" y1="25" x2="190" y2="25" />
          <line x1="10" y1="35" x2="190" y2="35" />
          <line x1="10" y1="45" x2="190" y2="45" />
          <line x1="183" y1="5" x2="183" y2="45" />
          <line x1="188" y1="5" x2="188" y2="45" style="stroke-width: 4;" class="end-line"/>
        </svg>
      </div>
      <div class="bar-elements">
        <div class="time-signature" >
          <div class="display-numerator">${
            i === 0 && !addChunkSameMeter ? obj.numer : ""
          }</div>
          <div class="display-denominator">${
            i === 0 && !addChunkSameMeter ? obj.denom : ""
          }</div>
        </div>
        <div class="notes">${notesHtml}</div>
      </div>
    </div>
  </div>`;
    measureDisplay.insertAdjacentHTML("beforeend", html);
  }
  measureDisplay.style["font-size"] = "smaller";
  // event.preventDefault();
  form.reset();
}
function processCountOff(obj) {
  if (countMap[0] < 0) return;
  let countOffCountMap = [];
  let countOffWaitTimes = [];
  const multiple = multiples[obj.beat];
  const beats = +obj.numer * 2;
  //convert BPM to wait time based on denom and multiple
  const countOffWait = (60 / +obj.aTemp / multiple / +obj.denom) * 4;

  for (let i = 0; i < beats; i++) {
    // 2 measures of wait times
    countOffWaitTimes.push(countOffWait);
  }

  for (let i = 0; i < 2; i++) {
    for (let j = 1; j <= +obj.numer; j++) {
      countOffCountMap.push(-j);
    }
  }
  countMap.unshift(...countOffCountMap);
  waitTimes.unshift(...countOffWaitTimes);
}
function createClickTrack() {
  audioContext = new AudioContext();
  oscillator = audioContext.createOscillator();
  oscillator.type = "square"; // You can change the type of the wave
  oscillator.frequency.value = 540; // You can change the frequency of the sound
  oscillator.start();

  // Create a gain node to control the volume of the click
  const gain = audioContext.createGain();
  gain.gain.value = 0; // Initially set the gain to zero, so no sound is heard

  // Connect the oscillator to the gain node, and the gain node to the destination
  oscillator.connect(gain);
  gain.connect(audioContext.destination);

  // Create Countoff
  let countOff = document.querySelector("#countoff").checked;
  if (countOff) {
    processCountOff(tempoMap[0]);
  } else if (countMap[0] < 0) {
    // Remove countoff
    const realDownbeat = countMap.findIndex((el) => el > 0);
    countMap = countMap.slice(realDownbeat);
    waitTimes = waitTimes.slice(realDownbeat);
  }

  // Define a function that plays a click and schedules the next one
  function playClick(index) {
    const currentTime = audioContext.currentTime;

    function beep(vol, freq) {
      gain.gain.setValueAtTime(vol, currentTime);
      oscillator.frequency.setValueAtTime(freq, currentTime);
      gain.gain.setValueAtTime(0, currentTime + 0.01);
    }

    !countMap[index] || countMap[index] === 1
      ? // regular downbeat
        beep(1, 1200)
      : countMap[index] === -1
      ? // countoff downbeat
        beep(0.3, 1200)
      : countMap[index] < 0
      ? // countoff regular beat
        beep(0.2, 700)
      : //regular beat
        beep(0.6, 700);

    // Check for Looping option
    let loop = document.querySelector("#loop").checked;

    // Get the next wait time from the array
    const nextWaitTime = waitTimes[index];

    if (index <= waitTimes.length && playing) {
      // Schedule the next click after the wait time
      myTimeout = setTimeout(() => playClick(index + 1), nextWaitTime * 1000);
    } else if (loop) {
      //Go back to the beginning
      myTimeout = setTimeout(() => playClick(0), nextWaitTime * 1000);
    } else {
      clearTimeout(myTimeout);
      setTimeout(() => oscillator.stop(), 500);
      startStop.innerText = "Start Click!";
      playing = false;
    }
  }
  // Start the first click
  setTimeout(() => playClick(0), 500);
}
function undoProcessChunk() {
  const select = document.getElementById("measure-display");
  const removedChunk = tempoMap.pop();
  const removedCounts = +removedChunk.new * +removedChunk.numer;

  waitTimes = waitTimes.slice(0, -removedCounts);
  countMap = countMap.slice(0, -removedCounts);

  for (let i = 0; i < +removedChunk.new; i++) {
    select.removeChild(select.lastChild);
  }
}
function handleStartStop() {
  if (!playing && waitTimes.length > 0) {
    playing = true;
    createClickTrack();
    startStop.innerText = "Stop";
  } else {
    clearTimeout(myTimeout);
    // oscillator.stop();
    playing = false;
    audioContext.close();
    startStop.innerText = "Start Click!";
  }
}
function displayTrackName(title) {
  trackTitleDiv.innerText = `${title}`;
}
function clearTrackName() {
  trackTitleDiv.innerText = "";
}
function handleSave() {
  // if (loadVisible || manageVisible) return;
  if (!tempoMap[0]) {
    alert("Nothing to save yet");
  } else {
    if (!sessionStorage.getItem("save-warning")) {
      alert(
        `Please be aware: saved tracks are stored in your browser's 'Local Storage' and should persist if you reload the page, or leave and come back. However, anything stored will disappear if you clear your browser cache and history.`
      );
      sessionStorage.setItem("save-warning", "true");
    }
    const trackName = prompt("Name this clicktrack");
    if (trackName === "")
      alert("Cannot give it a blank name, please try again");
    if (!trackName) return;
    localStorage.setItem(`${trackName}`, JSON.stringify(tempoMap));
    displayTrackName(trackName);
  }
}
function handleLoad() {
  const trackName = document.querySelector('input[name="load-track"]:checked');
  if (!trackName) return;
  loadSavedDiv.style.display = "none";
  loadSavedOptions.innerHTML = "";
  const savedMap = JSON.parse(localStorage.getItem(`${trackName.value}`));
  if (!tempoMap[0]) measureDisplay.innerHTML = "";
  savedMap.forEach((obj) => {
    tempoMap.push(obj);
    processChunk(obj);
  });
  loadVisible = false;
  displayTrackName(trackName.value);
  console.log(trackName);
}

function handleManageStorage() {
  if (manageVisible || loadVisible) return;
  const allKeys = Object.keys(localStorage);
  if (!allKeys[0]) {
    alert("No clicktracks to manage");
  } else {
    allKeys.forEach((key) => {
      const html = `<div>
        <input type="checkbox" id="${key}" name="${key}"/>
        <label for="${key}">${key}</label>
        </div>`;
      deleteOptions.insertAdjacentHTML("beforeend", html);
    });
    manageStorageDiv.style.display = "block";
    manageVisible = true;
  }
}
function handleDeleteStorage() {
  const checkboxes = document.querySelectorAll(
    '#delete-options input[type="checkbox"]:checked'
  );
  let selectedTracks = [];
  for (let i = 0; i < checkboxes.length; i++) {
    selectedTracks.push(checkboxes[i].name);
  }

  selectedTracks.forEach((track) => {
    localStorage.removeItem(track);
  });
  manageStorageDiv.style.display = "none";
  deleteOptions.innerHTML = "";
  manageVisible = false;
}
//////////////////////////////////////////////////
window.addEventListener("load", () => {
  if (!sessionStorage.getItem("visited")) {
    alert(
      `Welcome!\n\nAdd measures to build a clicktrack. You can use odd meters, change tempos, you can even loop playback.\n\nThis is a work in progress, I would love to get your feedback about how it works for you!`
    );
    sessionStorage.setItem("visited", "true");
  }
});

form.addEventListener("submit", (event) => {
  event.preventDefault();

  const data = new FormData(form);
  let obj = {};
  for (const entry of data) {
    obj[entry[0]] = entry[1];
  }
  if (obj.new === "") return;
  if (tempoMap.length === 0) measureDisplay.innerHTML = "";
  // add chunk to tempo map
  tempoMap.push(obj);
  // generate waitTimes, countMap and HTML for chunk
  processChunk(obj);
  endTempo.classList.add("hidden");
});

undoAsk.addEventListener("click", (e) => {
  e.preventDefault();
  if (tempoMap.length > 0) undo.classList.toggle("hidden");
});

undo.addEventListener("click", (e) => {
  e.preventDefault();
  undoProcessChunk();
  undo.classList.toggle("hidden");
});

tempoChanges.addEventListener("click", (e) => {
  e.preventDefault();
  endTempo.classList.toggle("hidden");
});

startStop.addEventListener("click", (e) => {
  e.preventDefault();
  handleStartStop();
});

clearBtn.addEventListener("click", (e) => {
  e.preventDefault();

  tempoMap = [];
  waitTimes = [];
  countMap = [];
  measureDisplay.innerHTML = `<span style="font-size: 14px; text-justify: center">Start adding measures to build your clicktrack!</span>`;
  clearTrackName();
});

saveBtn.addEventListener("click", (e) => {
  e.preventDefault();
  handleSave();
});

loadBtn.addEventListener("click", (e) => {
  e.preventDefault();

  if (loadVisible || manageVisible) return;
  const allKeys = Object.keys(localStorage);
  if (!allKeys.length > 0) {
    alert("No clicktracks stored");
    loadSavedDiv.style.display = "none";
  } else {
    allKeys.forEach((key) => {
      const html = `<label>
        <input type="radio" name="load-track" value="${key}">
        <span>${key}</span>
        </label>`;
      loadSavedOptions.insertAdjacentHTML("beforeend", html);
    });
    loadSavedDiv.style.display = "block";
    loadVisible = true;
  }

  loadSavedBtn.addEventListener("click", (e) => {
    e.preventDefault();
    handleLoad();
  });

  loadSavedBtnCancel.addEventListener("click", (e) => {
    e.preventDefault();

    loadSavedDiv.style.display = "none";
    loadSavedOptions.innerHTML = "";
    loadVisible = false;
  });
});

manageStorageBtn.addEventListener("click", (e) => {
  e.preventDefault();
  handleManageStorage();

  submitDelete.addEventListener("click", (e) => {
    e.preventDefault();
    handleDeleteStorage();
  });

  cancelDelete.addEventListener("click", (e) => {
    e.preventDefault();

    manageStorageDiv.style.display = "none";
    deleteOptions.innerHTML = "";
    manageVisible = false;
  });
});

// const bottomControls = document.querySelector(".bottom-controls");
// window.addEventListener("scroll", () => {
//   if (document.body.scrollHeight > window.screenY + 200) {
//     bottomControls.classList.add("sticky");
//   } else {
//     bottomControls.classList.remove("sticky");
//   }
// });
